import { amplify } from './acs-amplify.js';
var getCuratedSearchPath = function () {
  var rootDomain = 'https://www.acs.org';
  var _acs = window.acs;
  if (_acs && _acs.sso && _acs.sso.loginUrl && _acs.sso.loginUrl.indexOf('/bin/acs/sso/login') > -1) {
    rootDomain = _acs.sso.loginUrl
      .replace('/bin/acs/sso/login', '')
      .replace('https:', 'http:')
      .replace('http:', window.location.protocol)
      .replace('cmswwwtst', 'cmswwwdev');
  }
  return rootDomain + '/etc/designs/acs/curated/acs/_jcr_content.json?cacheBust=' + new Date().getTime();
};

var acsStatusConfig = {
  url: window.acs.sso.statusUrl || 'https://sso.acs.org/idp/status',
  data: {
    appid: 'WWWACS',
  },
  dataType: 'jsonp',
  jsonp: 'padding',
  cache: 'longpoll',
  topic: 'sso.status',
};

export function requests() {
  amplify.request.define('sso.status', 'ajax', acsStatusConfig);
  amplify.request.define('sso.status.noCache', 'ajax', acsStatusConfig); // not doing the polling thing right now

  amplify.request.define('cq5.whitelist', 'ajax', {
    url:
      acs && acs.page && acs.page.path
        ? acs.page.path
        : window && window.location && window.location.pathname
          ? window.location.pathname
          : '/' + '.whitelist.json?cacheBust=' + new Date().getTime(),
    dataType: 'json',
    type: 'GET',
    cache: true,
  });
  amplify.request.define('acs.search.curatedResult', 'ajax', {
    url: getCuratedSearchPath(),
    dataType: 'json',
    type: 'GET',
    cache: {
      type: 'persist',
      expires: 86400000, // one day expiration
    },
  });
}
requests();
