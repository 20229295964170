import './vendor/jquery/1.11.1/jquery.js';

import { amplify } from './acs-amplify.js';
window.amplify = amplify;

if (typeof acs === 'undefined') {
  acs = {};
}

if (typeof acs.ui === 'undefined') {
  acs.ui = {};
}

import './requests.js'; // import for the side effect of defining amplify request types
import './acs-sso.js';
import './acs-skipnav.js';
import './acs-user-menu.js';
import './acs-search.js';
import './acs-bootstrap-dropdown.js';

if (!!document.querySelector('div#search')) {
  acs.search.init();
}

// fetch use data

acs.sso.init().always(function () {
  // render the login menu
  acs.ui.loginMenu.init();

  // render mobile profile info into menu
  if (!!document.getElementById('my-menu')) {
    if (!!document.querySelector('.navbar-toggle.icon')) {
      document.querySelector('.navbar-toggle.icon').id = 'mainSiteProfileInfo';
      document.querySelector('.navbar-toggle.icon').classList.add('mm-list');
      document.querySelector('.navbar-toggle.icon').style.display = 'none';
    }
    document.getElementById('my-menu').classList.add('loggedout');
  }

  if (!!document.querySelector('#myAccountMenu')) {
    var myAccountMenuClone = document.querySelector('#myAccountMenu').cloneNode(true);
    myAccountMenuClone.id = 'mainSiteProfileInfo';
    myAccountMenuClone.classList.add('mm-list');
    if (!!document.querySelector('#mainSiteProfileInfo')) {
      document.querySelector('#mainSiteProfileInfo').append(myAccountMenuClone);
      if (!!document.querySelector('#mainSiteProfileInfo .heading')) {
        document.querySelector('#mainSiteProfileInfo .heading').textContent = acs.user.firstname + ' ' + acs.user.lastname;
        document.querySelector('#mainSiteProfileInfo .heading').classList.add('heading-height');
      }
    }
  }
});
